import { hotjar } from 'react-hotjar';

const hjrInitialize = () => {
  // TODO if  if (!isTestMode && !hotjar.initialized())
  // const isTestMode = process.env.REACT_APP_NODE_ENV === 'development';
  if (!hotjar.initialized()) {
    hotjar.initialize(process.env.REACT_APP_HOTJAR_ID, process.env.REACT_APP_HOTJAR_SV);
  }
};

// Identify the user
export const hjrIdentify = () => {
  hotjar.identify('USER_ID', { userProperty: 'value' });
};

export const hjrFireEvent = (event = 'button-click') => {
  hotjar.event(event);
};

// Update SPA state
export const hjrChangeState = (path = '') => {
  hotjar.stateChange(path);
};

export default hjrInitialize;
