import { socials } from '../../../utilities/configs/socials';

import './styles.scss';

const Social = () => (
  <ul className="socials">
    {Object.values(socials).map(({ icon, key, href }) => (
      <a className="socials__item" key={key} href={href} target="_blank" rel="noreferrer">
        <img width={32} height={32} src={icon} alt={key} />
      </a>
    ))}
  </ul>
);

export default Social;
