/* eslint-disable react/state-in-constructor */
import React, { Component } from 'react';
import ErrorPage from '../../pages/error/Error';

class ErrorBoundary extends Component {
  state = { errorInfo: null };

  componentDidCatch(_, errorInfo) {
    this.setState({
      errorInfo,
    });
  }

  render() {
    const { errorInfo } = this.state;
    const { children } = this.props;

    if (errorInfo) {
      return <ErrorPage />;
    }

    return children;
  }
}

export default ErrorBoundary;
