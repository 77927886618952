import { ReactComponent as CookieIcon } from '../../../../assets/icons/cookie.svg';

// Styles
import './CookieSettingsButton.scss';

const CookieSettingsButton = ({ onClick }) => (
  <div className="cookie_settings_button" onClick={onClick}>
    <CookieIcon />
  </div>
);

export default CookieSettingsButton;
