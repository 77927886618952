import './styles.scss';

export const Loader = () => (
  <div className="loading">
    <div />
    <div />
  </div>
);

export default Loader;
