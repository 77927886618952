import React, { useCallback, useEffect, useState } from 'react';
import Modal from 'react-modal';

// Components
import Button from 'views/ui-core/button/Button';

import { useTranslation } from 'react-i18next';
import CookieCustomizeForm from '../cookieCustomizeForm/CookieCustomizeForm';

import { clearAllCookies } from '../../../../constants/cookie';

// Styles
import styles from './CookiePanel.module.scss';
import {
  allAcceptConsent,
  allDeclineConsent,
  CONSENT_KEY,
  NOTIFICATION_KEY,
  NOTIFICATION_TYPES,
} from '../../../../constants/localStorage';
import { gsFirePageView } from '../../../../utilities/ga';
import useLocationChange from '../../../../hooks/useLocationChange';
import CookieSettingsButton from '../cookieSettingsButton/CookieSettingsButton';

import { initialiseThirdPartyServices } from './CookiePanel.utils';

import { customStyles } from './Cookiepanel.constant';

Modal.setAppElement('#root');

const CookiePanel = () => {
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
  const [isShowCookiePanel, setIsShowCookiePanel] = useState(false);
  const [isShowCookieSettingsButton, setIsShowCookieSettingsButton] = useState(false);

  const { t } = useTranslation();

  useLocationChange(gsFirePageView);

  useEffect(() => {
    const cookieConsent = localStorage.getItem(CONSENT_KEY);
    const parsedCookieConsent = JSON.parse(cookieConsent);

    if (parsedCookieConsent) {
      setIsShowCookieSettingsButton(true);
      setIsShowCookiePanel(false);
      initialiseThirdPartyServices(parsedCookieConsent);
    }
    if (!parsedCookieConsent) {
      setIsShowCookieSettingsButton(false);
      setIsShowCookiePanel(true);
    }
  }, []);

  const onAcceptCookie = useCallback(() => {
    const consentData = JSON.stringify({ ...allAcceptConsent, date: new Date().toISOString() });
    localStorage.setItem(CONSENT_KEY, consentData);
    localStorage.setItem(NOTIFICATION_KEY, NOTIFICATION_TYPES.COOKIE_PREFERENCES);
    window.location.reload();
  }, []);

  const onDeclineCookie = useCallback(() => {
    const consentData = JSON.stringify({ ...allDeclineConsent, date: new Date().toISOString() });
    localStorage.setItem(CONSENT_KEY, consentData);
    localStorage.setItem(NOTIFICATION_KEY, NOTIFICATION_TYPES.COOKIE_PREFERENCES);
    clearAllCookies();
    window.location.reload();
  }, []);

  const onSavePreferencesCookie = useCallback((consent) => {
    const consentData = JSON.stringify({ ...consent, date: new Date().toISOString() });
    localStorage.setItem(CONSENT_KEY, consentData);
    localStorage.setItem(NOTIFICATION_KEY, NOTIFICATION_TYPES.COOKIE_PREFERENCES);
    clearAllCookies();
    window.location.reload();
  }, []);

  const openModal = () => {
    setIsSettingsModalOpen(true);
    setIsShowCookiePanel(false);
  };

  const closeModal = () => {
    const cookieConsent = localStorage.getItem(CONSENT_KEY);
    if (!cookieConsent) setIsShowCookiePanel(true);
    setIsSettingsModalOpen(false);
  };

  const getCookieValue = () => {
    const value = localStorage.getItem(CONSENT_KEY);
    return JSON.parse(value);
  };

  return (
    <div>
      <Modal onRequestClose={() => closeModal} isOpen={isSettingsModalOpen} style={customStyles}>
        <CookieCustomizeForm
          onAccept={onAcceptCookie}
          onDecline={onDeclineCookie}
          onSavePreferences={onSavePreferencesCookie}
          onClose={closeModal}
          cookie={getCookieValue()}
        />
      </Modal>

      {isShowCookieSettingsButton && <CookieSettingsButton onClick={openModal} />}

      {isShowCookiePanel && (
        <div className={styles.panel}>
          <div className="container-primary">
            <div className={styles.content}>
              <div className={styles.main}>{t('cookie_description')}</div>
              <div className={styles.controls}>
                <Button
                  size="sm"
                  className={styles.control}
                  text={t('cookie_btn_customize')}
                  viewType="secondary"
                  onClick={openModal}
                />
                <Button
                  size="sm"
                  className={styles.control}
                  text={t('cookie_btn_reject')}
                  viewType="secondary"
                  onClick={onDeclineCookie}
                />
                <Button
                  size="sm"
                  className={styles.control}
                  viewType="primary"
                  text={t('cookie_btn_accept')}
                  onClick={onAcceptCookie}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CookiePanel;
