export const CONSENT_KEY = 'cookieConsent';
export const NOTIFICATION_KEY = 'defaultNotification';

export const NOTIFICATION_TYPES = {
  COOKIE_PREFERENCES: 'COOKIE_PREFERENCES',
};

export const CONSENT_NAMES = {
  analytics: 'analytics',
  marketing: 'marketing',
  functional: 'functional',
};
export const allDeclineConsent = {
  [CONSENT_NAMES.analytics]: false,
  [CONSENT_NAMES.marketing]: false,
  [CONSENT_NAMES.functional]: false,
};

export const allAcceptConsent = {
  [CONSENT_NAMES.analytics]: true,
  [CONSENT_NAMES.marketing]: true,
  [CONSENT_NAMES.functional]: true,
};

export const defaultConsent = {
  [CONSENT_NAMES.analytics]: false,
  [CONSENT_NAMES.marketing]: false,
  [CONSENT_NAMES.functional]: false,
};
export const defaultSettingsConsent = {
  [CONSENT_NAMES.analytics]: true,
  [CONSENT_NAMES.marketing]: true,
  [CONSENT_NAMES.functional]: true,
};
