import { Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';

import Default from '../views/components/lazy/Lazy';

import { publicRoutes } from './router-config';

const Router = () => (
  <Suspense fallback={<Default />}>
    <Routes>
      {publicRoutes.map(({ path, element, exact }) => (
        <Route key={path} path={path} element={element} exact={exact} />
      ))}
    </Routes>
  </Suspense>
);

export default Router;
