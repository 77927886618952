import { appleStoreIcon } from '../../assets/icons';

const download = {
  appStore: {
    icon: appleStoreIcon,
    name: 'App Store',
    href: 'https://apps.apple.com/tt/app/aller-retour/id1596942705',
  },
  // googlePlay: {
  //   icon: playMarketIcon,
  //   name: 'Google Play',
  //   href: 'https://play.google.com/store/apps/details?id=com.aller_retour',
  // },
};

export default download;

export { download };
