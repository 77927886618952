import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { book } from '../../../constants/book';
import useQuery from '../../../utilities/hooks/useQuery';

import { SearchIcon } from '../../../assets/icons';
import './styles.scss';

const Search = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const query = useQuery();

  const queryStringValue = query.get('text') || '';
  const [searchValue, setSearchValue] = useState(queryStringValue);
  const location = useLocation();
  const handleInputChange = ({ target: { value } }) => {
    setSearchValue(value);
  };

  useEffect(() => {
    if (location.pathname !== book.search) setSearchValue('');
  }, [location]);

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate(`${book.search}?text=${searchValue}`);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="search">
        <input
          onChange={handleInputChange}
          placeholder={t('input_placeholder')}
          className="input search__input"
          value={searchValue}
        />
        <button type="submit" className="button search__button">
          <img src={SearchIcon} alt="search" />
        </button>
      </div>
    </form>
  );
};

export default Search;
