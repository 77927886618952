import { browserStorage } from 'helpers/browserStorage';

import {
  enIcon,
  frIcon,
  // plIcon,
  deIcon,
  itIcon,
  // jaIcon,
  // roIcon,
  // trIcon,
  // zhIcon,
  // esIcon,
  // arIcon,
  ruIcon,
  // ukIcon,
} from '../../assets/icons';

export const languageInfo = {
  en: {
    icon: enIcon,
    label: 'ENG',
    value: 'en',
  },
  fr: {
    icon: frIcon,
    label: 'FRA',
    value: 'fr',
  },
  // uk: {
  //   icon: ukIcon,
  //   label: 'UA',
  //   value: 'uk',
  // },
  // pl: {
  //   icon: plIcon,
  //   label: 'PL',
  //   value: 'pl',
  // },
  de: {
    icon: deIcon,
    label: 'DE',
    value: 'de',
  },
  it: {
    icon: itIcon,
    label: 'IT',
    value: 'it',
  },
  // ja: {
  //   icon: jaIcon,
  //   label: 'JA',
  //   value: 'ja',
  // },
  // ro: {
  //   icon: roIcon,
  //   label: 'RO',
  //   value: 'ro',
  // },
  // tr: {
  //   icon: trIcon,
  //   label: 'TR',
  //   value: 'tr',
  // },
  // zh: {
  //   icon: zhIcon,
  //   label: 'CH',
  //   value: 'zh',
  // },
  // es: {
  //   icon: esIcon,
  //   label: 'ES',
  //   value: 'es',
  // },
  // ar: {
  //   icon: arIcon,
  //   label: 'AR',
  //   value: 'ar',
  // },
  ru: {
    icon: ruIcon,
    label: 'RU',
    value: 'ru',
  },
};

const checkLanguageForAvailability = (language = '') => {
  const isAvailable = Object.keys(languageInfo).includes(language);
  return isAvailable ? languageInfo[language] : languageInfo.en;
};

export const getLanguage = () => {
  const browserLang = window.navigator.language;
  const selectedLanguage = browserStorage.getItem('language');
  const language = selectedLanguage || browserLang;

  return checkLanguageForAvailability(language.substring(0, 2));
};
