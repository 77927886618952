import { facebookIcon, instagramIcon } from '../../assets/icons';

const socials = Object.freeze({
  // twitter: {
  //   icon: twitterIcon,
  //   key: 'Twitter',
  //   href: 'https://www.twitter.com',
  // },
  facebook: {
    icon: facebookIcon,
    key: 'Facebook',
    href: 'https://www.facebook.com/allerretourch',
  },
  instagram: {
    icon: instagramIcon,
    key: 'Instagram',
    href: 'https://instagram.com/allerretour.ch?igshid=YmMyMTA2M2Y=',
  },
});

export default socials;
export { socials };
