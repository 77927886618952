import './Switch.scss';

const Switch = ({ value = false, name = '', isDefault = false, onChange }) => {
  const handleChange = () => onChange({ consentName: name, selectedValue: !value });

  return (
    <div className="switch">
      <span>
        <input
          disabled={isDefault}
          type="checkbox"
          id="toggleInput"
          checked={value}
          onChange={handleChange}
        />
        <div className="switch__slider" onClick={handleChange} />
      </span>
    </div>
  );
};

export default Switch;
