/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useState } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';

// Constants
import { book } from 'constants/book';

// Components
import Search from 'views/components/search/Search';
import Language from 'views/components/language/Language';

// Icons
import { logoIcon, searchIcon, closeIcon } from 'assets/icons';

import './styles.scss';

const Header = () => {
  const [searchStatus, setSearchStatus] = useState(false);

  const toggleSearchStatus = () => setSearchStatus(!searchStatus);

  return (
    <header className="header">
      <div className="container-primary">
        <div
          className={cn('header__secondary-mob', {
            header__active: searchStatus,
            header__none: !searchStatus,
          })}
        >
          <div className="header__mob-search">
            <Search />
          </div>
          <img
            onClick={toggleSearchStatus}
            className="header__mob-close-icon"
            src={closeIcon}
            alt="search"
          />
        </div>
        <div className={`${!searchStatus ? 'header__active' : 'header__none'} header__primary-mob`}>
          <Link to={book.main}>
            <img className="logo header__logo" src={logoIcon} alt="aller retour" />
          </Link>

          <div className="header__search">
            <Search />
          </div>
          <div className="header__language">
            <Language />
          </div>
          <img
            onClick={toggleSearchStatus}
            className="header__mob-search-icon"
            src={searchIcon}
            alt="search"
          />
        </div>
      </div>
    </header>
  );
};

export default Header;
