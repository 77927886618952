const handleIntercomOpen = () => {
  if (window.Intercom) window.Intercom('show');
};

export const insertIntercomCode = () => {
  // Check your condition here
  // Create a new script element for Intercom
  const script = document.createElement('script');

  // Set the script content with your Intercom settings
  script.innerHTML = `
      window.intercomSettings = {
        api_base: 'https://api-iam.intercom.io',
        app_id: 'qdszq4xh',
      };
    `;

  // Append the script to the document's head
  document.head.appendChild(script);
};

export default handleIntercomOpen;
