import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { getLanguage } from '../utilities/configs/language';
import translationEn from './en.json';
import translationFr from './fr.json';
// import translationUK from './uk.json';
// import translationPL from './pl.json';
import translationDE from './de.json';
import translationIT from './it.json';
// import translationJA from './ja.json';
// import translationRO from './ro.json';
// import translationTR from './tr.json';
// import translationZH from './zh.json';
// import translationES from './es.json';
// import translationAR from './ar.json';
import translationRU from './ru.json';

const resources = {
  fr: {
    translation: translationFr,
  },
  en: {
    translation: translationEn,
  },
  // uk: {
  //   translation: translationUK,
  // },
  // pl: {
  //   translation: translationPL,
  // },
  de: {
    translation: translationDE,
  },
  it: {
    translation: translationIT,
  },
  // ja: {
  //   translation: translationJA,
  // },
  // ro: {
  //   translation: translationRO,
  // },
  // tr: {
  //   translation: translationTR,
  // },
  // zh: {
  //   translation: translationZH,
  // },
  // es: {
  //   translation: translationES,
  // },
  // ar: {
  //   translation: translationAR,
  // },
  ru: {
    translation: translationRU,
  },
};

const initializeLanguage = getLanguage();

i18n.use(initReactI18next).init({
  resources,
  lng: initializeLanguage.value,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
