import { useTranslation } from 'react-i18next';

// Components
import Layout from 'views/components/layout/Layout';

const App = () => {
  const { i18n } = useTranslation();
  document.body.dir = i18n.dir();

  return <Layout />;
};

export default App;
