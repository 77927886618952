export const clearAllCookies = () => {
  // Get all cookies
  const cookies = document.cookie.split(';');

  // Iterate through each cookie and delete it
  cookies.forEach((cookie) => {
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.lampawork.com`;
  });
};
