import { defaultConsent } from '../../../../constants/localStorage';

import hjrInitialize from '../../../../utilities/hotjar';
import gaInitialize from '../../../../utilities/ga';
import intercomInitialize from '../../../../modules/intercom';
import { insertFacebookPixelCode } from '../../../../utilities/facebookPixel';
import { insertIntercomCode } from '../../../../utilities/intercom';

export const initialiseThirdPartyServices = (consent = defaultConsent) => {
  if (consent.analytics) {
    gaInitialize();
    hjrInitialize();
  }

  if (consent.marketing) {
    insertFacebookPixelCode();
  }

  if (consent.functional) {
    insertIntercomCode();
    intercomInitialize();
  }
};
