import { useRef, useMemo, useState, useEffect, useCallback } from 'react';
import i18n from 'localization/i18n';

// Icons
import { ArrowIcon } from 'assets/icons';

// Helpers
import { browserStorage } from 'helpers/browserStorage';

// Hooks
import useClickOutside from 'utilities/hooks/useClickOutside';

// Utilities
import { getLanguage, languageInfo } from 'utilities/configs/language';

import './styles.scss';

const Language = () => {
  const [language, setLanguage] = useState(languageInfo.en);
  const [isOpenedDropdown, setIsOpenedDropdown] = useState(false);
  const dropdownRef = useRef();

  const openDropdown = useCallback(() => setIsOpenedDropdown(true), []);

  const closeDropdown = useCallback(() => setIsOpenedDropdown(false), []);

  useClickOutside(dropdownRef, isOpenedDropdown, closeDropdown);

  const handleChangeLanguage = useCallback(
    (lng) => () => {
      setLanguage(languageInfo[lng]);
      browserStorage.setItem('language', lng);
      setIsOpenedDropdown(false);
      i18n.changeLanguage(lng);
    },
    [],
  );

  const getLocationDropdownList = useMemo(() => {
    const copyLanguageInfo = JSON.parse(JSON.stringify(languageInfo));
    delete copyLanguageInfo[language.value];
    return Object.values(copyLanguageInfo);
  }, [language]);

  useEffect(() => {
    const siteLanguage = getLanguage();
    setLanguage(siteLanguage);
  }, []);

  return (
    <div className="language-control">
      <div className="language-control__selected" onClick={openDropdown}>
        <img className="language-control__icon" src={language.icon} alt={language.label} />
        <p className="language-control__text">{language.label}</p>
        <img src={ArrowIcon} alt="arrow down" />
      </div>

      {isOpenedDropdown && (
        <ul className="language-control__select-list" ref={dropdownRef}>
          {getLocationDropdownList.map(({ label, value, icon }) => (
            <li
              key={label}
              onClick={handleChangeLanguage(value)}
              className="language-control__select-item"
            >
              <img className="language-control__icon" src={icon} alt={label} />
              <p className="language-control__text" key={label}>
                {label}
              </p>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Language;
