export const customStyles = {
  overlay: {
    position: 'fixed',
    top: '0',
    right: '0',
    bottom: '0',
    left: '0',
    zIndex: '999',
    backgroundColor: 'rgba(0,0,0,0.8)',
  },
  content: {
    inset: 0,
    opacity: '1',
    width: '80%',
    height: '90%',
    maxWidth: '900px',
    margin: 'auto',
    backgroundColor: '#555',
    borderColor: '#555',
  },
};
