import React, { useEffect } from 'react';

// Styles
import 'assets/styles/normalize.scss';
import 'assets/styles/base.scss';

import Router from 'router/Router';

// Components
import Header from 'views/components/header/Header';
import Footer from 'views/components/footer/Footer';
import ScrollToTop from 'views/components/scrollToTop/ScrollToTop';
import CookiePanel from 'views/components/cookie/cookiePanel/CookiePanel';
import { Toaster } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { NOTIFICATION_KEY, NOTIFICATION_TYPES } from '../../../constants/localStorage';
import { notify } from '../../../utilities/toast';

const Layout = () => {
  const { t } = useTranslation();

  useEffect(() => {
    const getDefaultNotification = () => {
      const defaultNotificationName = localStorage.getItem(NOTIFICATION_KEY);
      if (defaultNotificationName === NOTIFICATION_TYPES.COOKIE_PREFERENCES) {
        notify(`${t('cookie_success_notification')} ✅`);
        localStorage.removeItem(NOTIFICATION_KEY);
      }
    };

    getDefaultNotification();
  });

  return (
    <>
      <Toaster />
      <ScrollToTop />
      <Header />
      <main className="main">
        <Router />
      </main>
      <Footer />
      <CookiePanel />
    </>
  );
};

export default Layout;
