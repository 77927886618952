import { Link } from 'react-router-dom';

// Constants
import { book } from 'constants/book';

// Components
import Download from 'views/components/download/Download';
import Navigation from 'views/components/navigation/Navigation';
import Social from 'views/components/social/Social';

// Icons
import { logoIcon } from 'assets/icons';

import './styles.scss';

const Footer = () => (
  <footer className="footer">
    <div className="container-primary footer__inner">
      <Link to={book.main}>
        <img className="logo footer__logo" src={logoIcon} alt="aller retour" />
      </Link>

      <Navigation />

      <div className="footer__divider" />

      <Social />
    </div>

    <div className="container-primary footer__inner footer__inner_bottom">
      <Download />
    </div>
  </footer>
);

export default Footer;
