import { lazy } from 'react';

import { book } from 'constants/book';

const Main = lazy(() => import('views/pages/main/Main'));
const Partners = lazy(() => import('views/pages/partners/Partners'));
const Partner = lazy(() => import('views/pages/partner/Partner'));
const Categories = lazy(() => import('views/pages/categories/Categories'));
const Category = lazy(() => import('views/pages/category/Category'));
const Search = lazy(() => import('views/pages/search/Search'));
const NotFound = lazy(() => import('views/pages/notFound/NotFound'));
const Faq = lazy(() => import('views/pages/faq/Faq'));
const Policy = lazy(() => import('views/pages/policy/Policy'));
const Terms = lazy(() => import('views/pages/terms/Terms'));
const Support = lazy(() => import('views/pages/support/Support'));
const CookiePolicy = lazy(() => import('views/pages/cookiePolicy/CookiePolicy'));

const publicRoutes = [
  {
    element: <Main />,
    path: book.main,
    exact: true,
  },
  {
    element: <Partners />,
    path: book.partners,
    exact: true,
  },
  {
    element: <Partner />,
    path: book.currentPartner,
  },
  {
    element: <Categories />,
    path: book.categories,
    exact: true,
  },
  {
    element: <Category />,
    path: book.currentCategory,
  },
  {
    element: <Search />,
    path: book.search,
  },
  {
    element: <Faq />,
    path: book.faq,
  },
  {
    element: <Policy />,
    path: book.privacy,
  },
  {
    element: <Terms />,
    path: book.terms,
  },
  {
    element: <Support />,
    path: book.support,
  },
  {
    element: <CookiePolicy />,
    path: book.cookiePolicy,
  },
  {
    element: <NotFound />,
    path: book.all,
  },
];

export { publicRoutes };

export default publicRoutes;
