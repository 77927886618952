import toast from 'react-hot-toast';

export const notify = (text, opt) =>
  toast(text, {
    position: 'top-center',
    duration: 3000,
    style: {
      borderRadius: '5px',
      background: '#333',
      color: '#fff',
    },
    ...opt,
  });
