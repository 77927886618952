export class CreateBrowserStorage {
  constructor(storage) {
    this.storage = storage;
  }

  getItem = (itemName) => this.storage.getItem(itemName);

  setItem = (itemName, itemValue) => {
    this.storage.setItem(itemName, itemValue);
  };

  removeItem = (itemName) => {
    this.storage.removeItem(itemName);
  };

  removeItems = (items) => {
    if (!Array.isArray(items)) {
      throw new TypeError(`${items} must be an array`);
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const item of items) {
      this.removeItem(item);
    }
  };
}

export default CreateBrowserStorage;
