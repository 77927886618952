import { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { navigation } from 'utilities/configs/navigation';
import handleIntercomOpen from 'utilities/intercom';

import './styles.scss';

const Navigation = () => {
  const { t } = useTranslation();

  const renderItemComponent = useCallback(
    ({ label, inner, path }) => {
      if (inner) {
        return (
          <li className="navigation__item" key={label}>
            <Link className="navigation__link" to={path}>
              {t(label)}
            </Link>
          </li>
        );
      }

      if (path === 'intercom') {
        return (
          <li className="navigation__item" key={label}>
            <span className="navigation__link" onClick={handleIntercomOpen}>
              {t(label)}
            </span>
          </li>
        );
      }

      return (
        <li className="navigation__item" key={label}>
          <a className="navigation__link" target="_blank" rel="noreferrer" href={path}>
            {t(label)}
          </a>
        </li>
      );
    },

    [t],
  );

  return (
    <nav className="navigation">
      <ul className="navigation__list">{Object.values(navigation).map(renderItemComponent)}</ul>
    </nav>
  );
};

export default Navigation;
