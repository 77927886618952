import React from 'react';
import { Loader } from 'views/components/loader/Loader';

import './styles.scss';

const Lazy = () => (
  <div className="lazy">
    <Loader />
  </div>
);

export default Lazy;
