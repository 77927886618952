import { useTranslation } from 'react-i18next';
import Button from '../../ui-core/button/Button';

import { errorIcon } from '../../../assets/icons/index';

import './styles.scss';

export const Error = () => {
  const { t } = useTranslation();

  return (
    <div className="error-page">
      <div className="container-primary">
        <div className="error-page__content">
          <div className="error-page__info">
            <h1 className="error-page__title">{t('error_title')}</h1>
            <p className="error-page__description">{t('error_description')}</p>
            <div onClick={() => window.location.reload()} className="error-page__button">
              <Button text="error_refresh" />
            </div>
          </div>

          <img className="error-page__icon" src={errorIcon} alt="error" />
        </div>
      </div>
    </div>
  );
};

export default Error;
