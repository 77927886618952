import cn from 'classnames';

import './CookieInfo.scss';

const CookieInfo = ({ cookie, isActive }) => (
  <div className={cn('cookie_info', { 'cookie_info--active': isActive })}>
    {cookie.map(({ cookieName, cookieDuration, cookieDescription }) => (
      <div key={cookieName + cookieDuration} className="cookie_item">
        <dl className="cookie_item__list">
          <div className="cookie_item__el">
            <dt className="cookie_item__name">Cookie</dt>
            <dd className="cookie_item__value">{cookieName}</dd>
          </div>
          <div className="cookie_item__el">
            <dt className="cookie_item__name">Duration</dt>
            <dd className="cookie_item__value">{cookieDuration}</dd>
          </div>
          <div className="cookie_item__el">
            <dt className="cookie_item__name">Description</dt>
            <dd className="cookie_item__value">{cookieDescription}</dd>
          </div>
        </dl>
      </div>
    ))}
  </div>
);

export default CookieInfo;
