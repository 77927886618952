import { useEffect, useCallback } from 'react';

export default function useClickOutside(dropdownRef, isOpened, callback) {
  const documentClickHandler = useCallback(
    (e) => {
      const dropdownNode = dropdownRef.current;

      if (dropdownNode && e.target !== dropdownNode && !dropdownNode.contains(e.target)) {
        callback();
      }
    },
    [dropdownRef, callback],
  );

  useEffect(() => {
    if (isOpened) {
      document.addEventListener('click', documentClickHandler, { passive: true });
    }
    return () => {
      document.removeEventListener('click', documentClickHandler, { passive: true });
    };
  }, [documentClickHandler, isOpened]);
}
