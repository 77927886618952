export const book = Object.freeze({
  all: '*',
  main: '/',
  partners: '/partners',
  currentPartner: '/partners/:id',
  categories: '/categories',
  currentCategory: '/categories/:id',
  search: '/search',
  faq: '/faq',
  privacy: '/privacy-policy',
  terms: '/terms',
  support: '/support',
  notFound: '/not-found',
  cookiePolicy: '/cookie-policy',
});

export default book;
