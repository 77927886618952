import { book } from '../../constants/book';

const navigation = {
  home: {
    label: 'nav_link_home',
    path: book.main,
    inner: true,
  },
  partners: {
    label: 'nav_link_partners',
    path: book.partners,
    inner: true,
  },
  support: {
    label: 'nav_link_support',
    path: book.support,
    inner: true,
  },
  privacyPolicy: {
    label: 'nav_link_policy',
    path: book.privacy,
    inner: true,
  },
  terms: {
    label: 'nav_link_terms',
    path: book.terms,
    inner: true,
  },
  faq: {
    label: 'nav_link_faq',
    path: book.faq,
    inner: true,
  },
  cookiePolicy: {
    label: 'nav_link_cookie',
    path: book.cookiePolicy,
    inner: true,
  },
};

export default navigation;
export { navigation };
