import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';

import { closeIcon } from 'assets/icons';

import Button from '../../../ui-core/button/Button';
import CookieConsent from '../cookieConsent/CookieConsent';

import './CookieCustomizeForm.scss';

import { CONSENT_NAMES, defaultSettingsConsent } from '../../../../constants/localStorage';

const CookieCustomizeForm = ({ onClose, onAccept, onDecline, onSavePreferences, cookie }) => {
  const { t } = useTranslation();

  const [consent, setConsent] = useState(cookie || defaultSettingsConsent);

  const handleChangeConsent = ({ consentName, selectedValue }) => {
    setConsent({ ...consent, [consentName]: selectedValue });
  };

  const handleSavePreferences = () => onSavePreferences(consent);

  return (
    <div className="customize-cookie">
      <div className="customize-cookie__head">
        <p className="customize-cookie__head_title">{t('cookie_customize_title')}</p>
        <button className="customize-cookie__head_button" type="button" onClick={onClose}>
          <img src={closeIcon} alt="close icon" />
        </button>
      </div>
      <div className="customize-cookie__content">
        <p className="customize-cookie__description">{t('cookie_customize_description')}</p>

        <CookieConsent
          key={CONSENT_NAMES.analytics}
          isDefault
          inputName={CONSENT_NAMES.analytics}
          onChange={handleChangeConsent}
          value={consent.analytics}
          name={t('cookie_customize_consent_1_title')}
          description={t('cookie_customize_consent_1_description')}
        />
        <CookieConsent
          key={CONSENT_NAMES.marketing}
          inputName={CONSENT_NAMES.marketing}
          onChange={handleChangeConsent}
          value={consent.marketing}
          name={t('cookie_customize_consent_2_title')}
          description={t('cookie_customize_consent_2_description')}
        />
        <CookieConsent
          key={CONSENT_NAMES.functional}
          inputName={CONSENT_NAMES.functional}
          onChange={handleChangeConsent}
          value={consent.functional}
          name={t('cookie_customize_consent_3_title')}
          description={t('cookie_customize_consent_3_description')}
        />
      </div>
      <div className="customize-cookie__footer">
        <Button
          className="customize-cookie__footer_button"
          viewType="secondary"
          size="md"
          text={t('cookie_btn_reject')}
          onClick={onDecline}
        />
        <Button
          className="customize-cookie__footer_button"
          viewType="primary"
          text={t('cookie_btn_preferences')}
          size="md"
          onClick={handleSavePreferences}
        />
        <Button
          className="customize-cookie__footer_button"
          viewType="primary"
          text={t('cookie_btn_accept')}
          size="md"
          onClick={onAccept}
        />
      </div>
    </div>
  );
};

export default CookieCustomizeForm;
