import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useLocationChange = (callback) => {
  const location = useLocation();

  useEffect(() => {
    if (typeof callback === 'function') {
      callback();
    }
  }, [location, callback]);
};

export default useLocationChange;
