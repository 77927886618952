import { useState } from 'react';
import { ArrowIcon } from 'assets/icons';

import cn from 'classnames';

import Switch from '../../../ui-core/switch/Switch';
import CookieInfo from '../cookieInfo/CookieInfo';

import './CookieConsent.scss';

const CookieConsent = ({
  isDefault = false,
  name = '',
  description = '',
  cookie = [],
  inputName = '',
  onChange = () => {},
  value = false,
}) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div className="consent">
      <div className="consent_item">
        <div className="consent_item__head">
          <div onClick={() => setIsActive(!isActive)} className="consent_item__name">
            {Boolean(cookie.length) && (
              <img
                className={cn('consent_item__icon', { 'consent_item__icon--active': isActive })}
                src={ArrowIcon}
                alt="close icon"
              />
            )}
            {name}
          </div>
          <Switch isDefault={isDefault} value={value} name={inputName} onChange={onChange} />
        </div>
        <p className="consent_item__description">{description}</p>
        {Boolean(cookie.length) && <CookieInfo cookie={cookie} isActive={isActive} />}
      </div>
    </div>
  );
};

export default CookieConsent;
