import ReactGA from 'react-ga';

const gaInitialize = () => {
  const isTestMode = process.env.REACT_APP_NODE_ENV === 'development';
  ReactGA.initialize(process.env.REACT_APP_GA_KEY, { testMode: false, debug: isTestMode });
};

export const gsFirePageView = () => {
  ReactGA.pageview(window.location.pathname + window.location.search);
};

export default gaInitialize;
