import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import './styles.scss';

const Button = ({
  className,
  text,
  type = 'button',
  viewType = 'primary',
  size = 'lg',
  onClick = () => {},
}) => {
  const { t } = useTranslation();

  return (
    <button
      className={cn(`button button-${viewType} button-${size}`, className)}
      // eslint-disable-next-line react/button-has-type
      type={type}
      onClick={onClick}
    >
      {t(text)}
    </button>
  );
};

export default Button;
