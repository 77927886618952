import { download } from '../../../utilities/configs/download';

import './styles.scss';

const Download = () => (
  <ul className="download">
    {Object.values(download).map(({ icon, name, href }) => (
      <a className="download__item" key={name} href={href} target="_blank" rel="noreferrer">
        <img className="download__icon" src={icon} alt={name} />
      </a>
    ))}
  </ul>
);

export default Download;
